import { Link } from 'react-router-dom';

import { ScheduleStyled } from './schedule.styled';

export const Schedule = () => (
	<ScheduleStyled>
		<h1>Programma</h1>
		<ul>
			<li>
				<time>09:33 - 09:45</time>
				<a
					target="_blank"
					href="https://www.ns.nl/reisplanner/#/?vertrek=Den%20Haag%20Centraal&vertrektype=treinstation&aankomst=Leiden%20Centraal&aankomsttype=treinstation&type=aankomst&tijd=2022-11-05T09%3A45"
				>
					Trein naar Leiden
				</a>
			</li>
			<li>
				<time>10:00 - 11:00</time>
				<Link to="/wabisabi">Wabi Sabi</Link>
			</li>
			<li>
				<time>11:15 - 12:15</time>
				<a target="_blank" href="https://goo.gl/maps/exDKo5oMKEiwkn7D8">
					Trein naar Amsterdam
				</a>
			</li>
			<li>
				<time>12:15 - 13:30</time>
				<Link to="/zushi">Zushi</Link>
			</li>
			<li>
				<time>14:00 - 16:00</time>
				<Link to="/kaligrafie">Kaligrafie Workshop</Link>
			</li>
			<li>
				<time>16:15</time>
				<Link to="/otaking">Otaking</Link>
			</li>
			<li>
				<time>18:30</time>
				<Link to="/akitsu">Akitsu</Link>
			</li>
		</ul>
	</ScheduleStyled>
);

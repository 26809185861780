import { OtakingStyled } from './otaking.styled';

export const Otaking = () => (
	<OtakingStyled>
		<h1>Otaking</h1>
		<time>16:15</time>
		<p>The only gashapon shop in europe.</p>
		<p>
			Gashapon (ガシャポン), also called gachapon (ガチャポン), are a variety of vending
			machine-dispensed capsule toys that originated in the 1960s and became popular in Japan
			and elsewhere.
		</p>
		<p>
			Bij Otaking hebben ze tientallen gachapon automaten, net als je in Japan zou tegenkomen.
			Voor slechts een paar euro kun je hier kleine verzamelfiguurtjes, sleutelhangers, en
			andere hebbedingetjes uit de automaat trekken.
		</p>
		<a target="_blank" href="https://otaking.tv/">
			Otaking website
		</a>
	</OtakingStyled>
);

import { Route, Routes } from 'react-router-dom';

import { Akitsu } from '../akitsu';
import { KaligrafieWorkshop } from '../kaligrafie-workshop';
import { Layout } from '../layout';
import { Otaking } from '../otaking';
import { Schedule } from '../schedule';
import { WabiSabi } from '../wabi-sabi';
import { Zushi } from '../zushi';

export const App = () => {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Schedule />} />
				<Route path="wabisabi" element={<WabiSabi />} />
				<Route path="zushi" element={<Zushi />} />
				<Route path="kaligrafie" element={<KaligrafieWorkshop />} />
				<Route path="otaking" element={<Otaking />} />
				<Route path="akitsu" element={<Akitsu />} />
			</Route>
		</Routes>
	);
};

import { WabiSabiStyled } from './wabi-sabi.styled';

export const WabiSabi = () => (
	<WabiSabiStyled>
		<h1>WABI SABI</h1>
		<time>10:00 - 11:00</time>
		<p>Een tentoonstelling gewijd aan dit esthetische concept in fotografie en keramiek.</p>
		<p>
			Ruim honderd werken van zeventien nationale en internationale kunstenaars maken dit
			Japanse begrip inzichtelijk. De acceptatie en waardering van vergankelijkheid en
			imperfectie staan hierbij centraal en komen terug in alle Japanse kunstvormen, zoals bij
			de theeceremonie, in poëzie en in de prentkunst. De kunstenaars die deel uitmaken van de
			tentoonstelling zijn allen op hun eigen wijze beïnvloed door wabi-sabi. Dat is
			bijvoorbeeld te zien in een foto van smeltende sneeuw, gedrukt op handgeschept papier,
			of bij een simpele asymmetrische theekom met een barst in het glazuur.
		</p>
		<a
			target="_blank"
			href="https://www.sieboldhuis.org/tentoonstellingen/wabi-sabi-japanse-esthetiek-in-fotografie-en-keramiek"
		>
			Wabi Sabi tentoonstelling
		</a>
	</WabiSabiStyled>
);

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	body {
		background-color: purple;
		font-family: 'Comfortaa', cursive;
		color: white;
		font-size: 1.2rem;
		padding: 2rem;
		
		@media (min-width: 40rem) {
			font-size: 2rem;
		}
	}
`;

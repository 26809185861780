import styled from 'styled-components';

export const LayoutStyled = styled.div`
	@media (min-width: 40rem) {
		margin-right: auto;
		margin-left: auto;
		max-width: 40rem;
	}

	h1,
	time {
		font-family: 'Azeret Mono', monospace;
	}

	h1 {
		font-size: 3rem;
		color: pink;
		text-shadow: 0.2rem 0.2rem 0.2rem deeppink;
	}

	time {
		display: block;
		font-size: 1rem;
		color: deeppink;
	}

	a {
		text-decoration: none;
		font-family: 'Azeret Mono', monospace;
		color: pink;
	}

	ul,
	li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		margin-bottom: 1rem;
	}
`;

import { ZushiStyled } from './zushi.styled';

export const Zushi = () => (
	<ZushiStyled>
		<h1>Zushi</h1>
		<time>12:15 - 13:30</time>
		<p>
			Zushi is een van de weinige restaurants in Nederland met sushi aan de lopende band. Onze
			sushibar met lopende band in Amsterdam staat sinds 1999 synoniem voor heerlijke verse
			kwaliteit sushi en grillgerechten in een ontspannen en gezellige ambiance.
		</p>
		<p>
			Onze sushibar is gesitueerd midden in Amsterdam, in een statig 19e-eeuws pand dat
			prachtig uitzicht biedt over de Amstel. U vindt ons in de buurt van diverse theaters.
			Geniet van de unieke combinatie van ons historische pand in Amsterdam en de moderne
			sushi aan de lopende band.
		</p>
		<a target="_blank" href="https://zushi.nl/nl/#menus">
			Menu
		</a>
	</ZushiStyled>
);

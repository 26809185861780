import { KaligrafieWorkshopStyled } from './kaligrafie-workshop.styled';

export const KaligrafieWorkshop = () => (
	<KaligrafieWorkshopStyled>
		<h1>Kaligrafie workshop</h1>
		<time>14:00 - 16:00</time>
		<p>Maak kennis met de beginselen van het Japanse schoonschrift, shūji 習字. </p>
		<p>
			Met shūji wordt schoonschrift in het algemeen aangeduid. Leerlingen op school worden
			onderwezen in de correcte houding en penseelgreep; deze liggen aan de basis van de
			creatie van stevige, expressieve streken van zowel kanji als kana.{' '}
		</p>
		<p>
			Onder leiding van Japanoloog Owen Tjon Sie Fat kunt u ervaren hoe het werken met fude
			(penseel) en hanshi (rijstpapier) leidt tot de kenmerkende esthetische regels van de
			Japanse kalligrafie. U krijgt een hoop achtergrondinformatie over kalligrafie en de
			taal, en zal zelf tekens leren schrijven (shūji).
		</p>
	</KaligrafieWorkshopStyled>
);

import { Link, Outlet, useLocation } from 'react-router-dom';

import { GlobalStyle } from './global.styled';
import { LayoutStyled } from './layout.styled';

export const Layout = () => {
	const location = useLocation();
	const isHomePage = location.pathname === '/';
	return (
		<LayoutStyled>
			<GlobalStyle />
			{!isHomePage && <Link to="/">&lt;-- Programma</Link>}
			<Outlet />
		</LayoutStyled>
	);
};

import { AkitsuStyled } from './akitsu.styled';

export const Akitsu = () => (
	<AkitsuStyled>
		<h1>Akitsu</h1>
		<time>18:30</time>
		<p>Op de hoek van de Rozengracht, naast de Brandweerkazerne, vind je Akitsu. </p>
		<p>
			De kaart is traditioneel Japans en biedt meer dan alleen standaard sushi. Verwacht hoge
			kwaliteit Japanse lekkernij.
		</p>
		<a
			target="_blank"
			href="https://www.akitsu-amsterdam.com/wp-content/uploads/2020/07/Akitsu-Menu202007.pdf"
		>
			Menu
		</a>
	</AkitsuStyled>
);
